<template>
  <a-modal v-model:visible="coverVisible" title="选择图片" width="1088px">
    <template #footer>
      <a-button key="submit" type="primary" @click="chooseItem">选择</a-button>
    </template>
    <file></file>
  </a-modal>

  <a-modal v-model:visible="linkVisible">
    <span>跳转链接：</span>
    <a-input
      style="margin: 20px 0; width: 280px"
      v-model:value="setLinkData"
      placeholder="请输入跳转链接"
    />

    <template #footer>
      <a-button key="submit" type="primary" @click="chooseLink()"
        >选择</a-button
      >
    </template>
  </a-modal>

  <div style="background-color: #f0f2f5">
    <div
      style="
        width: 100%;
        height: 64px;
        margin-bottom: 10px;
        background-color: #ffffff;
      "
    >
      <div
        style="
          width: 120px;
          line-height: 64px;
          margin: 0 20px;
          display: inline-block;
        "
      >
        <a-button type="primary" @click="addNav(treeData)"
          >+ 添加一级导航</a-button
        >
      </div>
      <div style="line-height: 64px; margin: 0 20px; display: inline-block">
        <a-button type="primary" ghost @click="closeNav()">
          <template #icon>
            <icon-font v-if="allCloseNavStatus === 'none'" type="wapi-right" />
            <icon-font
              v-else-if="allCloseNavStatus === 'block'"
              type="wapi-down"
            />
          </template>
          <span v-if="allCloseNavStatus === 'none'">全部展开</span>
          <span v-else-if="allCloseNavStatus === 'block'">全部折叠</span>
        </a-button>
      </div>
      <div style="display: inline-block">
        排序：
        <a-switch v-model:checked="sortSwitch" @change="sort()" />
      </div>
    </div>
    <div class="tree-table-box">
      <div
        class="header"
        style="
          display: flex;
          height: 64px;
          line-height: 64px;
          position: relative;
          text-align: center;
          font-size: 16px;
        "
      >
        <div v-for="(item, index) in columns" :key="index">
          <div
            v-if="index === 0"
            style="min-width: 600px; position: absolute; left: 0"
          >
            {{ item }}
          </div>
          <div
            v-if="index === 1"
            style="width: 100px; position: absolute; right: 260px"
          >
            {{ item }}
          </div>
          <div
            v-if="index === 2"
            style="width: 80px; position: absolute; right: 180px"
          >
            {{ item }}
          </div>
          <div
            v-if="index === 3"
            style="width: 180px; position: absolute; right: 0"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="tree-table-one">
        <a-empty v-if="treeData.length === 0" :image="simpleImage">
          <template #description>
            <span style="color: #c5c5c5"> 暂无数据 </span>
          </template>
          <a-button
            style="margin-top: 20px"
            type="primary"
            @click="addNav(treeData)"
            >添加一级导航</a-button
          >
        </a-empty>
        <div
          v-for="(item, index) in treeData"
          :key="item.sort"
          class="tree-table-one-item"
        >
          <div class="drag-item">
            <div class="tree-table-out tree-table">
              <div class="content content-bg-one">
                <div class="icon my-handle-one">
                  <icon-font type="wapi-menu" />
                </div>
                <div class="tree">
                  <div>
                    <div class="icon-box" @click="closeNav(index)">
                      <icon-font
                        v-if="item.closeNavStatus === 'none'"
                        style="font-size: 18px; color: #b8b9bd"
                        type="wapi-caret-right"
                      />
                      <icon-font
                        v-else-if="item.closeNavStatus === 'block'"
                        style="font-size: 18px; color: #b8b9bd"
                        type="wapi-caret-down"
                      />
                    </div>
                    <span class="title">一级</span>
                    <a-input
                      style="margin: 20px 0; width: 220px"
                      v-model:value="item.title"
                      placeholder="请输入导航名称"
                      @change="inputChange(index)"
                      :suffix="item.title.length + '/10'"
                    />
                    <a-button type="link" @click="addNavTwo(index, item)"
                      >+ 添加二级导航</a-button
                    >
                  </div>
                </div>
                <div class="image">
                  <div class="image-content">
                    <div
                      v-if="item.cover === '' || item.cover === null"
                      @click="chooseCover(index)"
                    >
                      <icon-font
                        type="wapi-plus"
                        style="
                          width: 40px;
                          height: 40px;
                          -webkit-box-sizing: border-box;
                          box-sizing: border-box;
                          border-radius: 2px;
                          border: 1px solid #e9edef;
                          background-color: #ffffff;
                          line-height: 40px;
                        "
                      />
                    </div>
                    <div v-else style="position: relative">
                      <img
                        :src="item.cover"
                        alt=""
                        @mouseover="mouseMoveCover(index)"
                        @mouseleave="mouseLeaveCover(index)"
                      />
                      <div class="cover-tips" style="display: none">
                        <div
                          class="cover-delete-btn"
                          @mouseover="mouseMoveCoverDelete(index)"
                          @click="deleteCover(index)"
                        >
                          <icon-font
                            type="wapi-close-circle"
                            style="color: #b8b9bd"
                          />
                        </div>
                        <div
                          class="cover-change-btn"
                          @mouseover="mouseMoveCoverChange(index)"
                          @mouseleave="mouseLeaveCover(index)"
                          @click="chooseCover(index)"
                        >
                          替换
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="status"
                  style="width: 80px; padding: 0 10px; text-align: center"
                >
                  <a-tag
                    v-if="item.status === 1"
                    color="success"
                    style="margin: 0 auto; cursor: pointer"
                    @click="
                      item.status = 0;
                      changeItemStatus();
                    "
                    >显示中
                  </a-tag>
                  <a-tag
                    v-else-if="item.status === 0"
                    color="default"
                    style="margin: 0 auto; cursor: pointer"
                    @click="
                      item.status = 1;
                      changeItemStatus();
                    "
                    >已隐藏
                  </a-tag>
                </div>
                <div class="action">
                  <a-button
                    type="link"
                    style="display: inline-block; padding: 0 5px"
                    v-if="item.link === ''"
                    @click="setLink(index)"
                    >设置链接
                  </a-button>
                  <a-button
                    type="link"
                    style="display: inline-block; padding: 0 5px"
                    v-else
                    @click="updateLink(index)"
                  >
                    更新链接
                  </a-button>
                  <a-button
                    type="link"
                    danger
                    style="display: inline-block; padding: 0 5px"
                    @click="deleteItem(1, index)"
                    >删除
                  </a-button>
                </div>
              </div>
              <div
                v-if="item.children && item.children.length > 0 && level > 1"
              >
                <div
                  class="tree-table-two"
                  v-bind:style="{ display: item.closeNavStatus }"
                >
                  <div
                    v-for="(item2, index2) in item.children"
                    :key="item2.sort"
                    class="tree-table-two-item"
                  >
                    <div class="drag-item">
                      <div class="tree-table tree-table-in">
                        <div class="content content-bg-two">
                          <div class="icon my-handle-two">
                            <icon-font type="wapi-menu" />
                          </div>
                          <div class="tree" style="padding-left: 50px">
                            <div>
                              <div class="icon-box">
                                <icon-font
                                  style="font-size: 18px; color: #b8b9bd"
                                  type="wapi-xiaji"
                                />
                              </div>
                              <div class="icon-box"></div>
                              <span class="title">二级</span>
                              <a-input
                                style="margin: 20px 0; width: 200px"
                                v-model:value="item2.title"
                                placeholder="请输入导航名称"
                                show-count
                                :maxlength="10"
                                :suffix="item2.title.length + '/10'"
                              />
                            </div>
                          </div>
                          <div class="image">
                            <div class="image-content">
                              <div
                                v-if="
                                  item2.cover === '' || item2.cover === null
                                "
                                @click="chooseCover(index, index2)"
                              >
                                <icon-font
                                  type="wapi-plus"
                                  style="
                                    width: 40px;
                                    height: 40px;
                                    -webkit-box-sizing: border-box;
                                    box-sizing: border-box;
                                    border-radius: 2px;
                                    border: 1px solid #e9edef;
                                    background-color: #ffffff;
                                    line-height: 40px;
                                  "
                                />
                              </div>
                              <div v-else style="position: relative">
                                <img
                                  :src="item2.cover"
                                  alt=""
                                  @mouseover="mouseMoveCover(index, index2)"
                                  @mouseleave="mouseLeaveCover(index, index2)"
                                />
                                <div class="cover-tips" style="display: none">
                                  <div
                                    class="cover-delete-btn"
                                    @mouseover="
                                      mouseMoveCoverDelete(index, index2)
                                    "
                                    @click="deleteCover(index, index2)"
                                  >
                                    <icon-font
                                      type="wapi-close-circle"
                                      style="color: #b8b9bd"
                                    />
                                  </div>
                                  <div
                                    class="cover-change-btn"
                                    @mouseover="
                                      mouseMoveCoverChange(index, index2)
                                    "
                                    @mouseleave="mouseLeaveCover(index, index2)"
                                    @click="chooseCover(index, index2)"
                                  >
                                    替换
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            class="status"
                            style="
                              width: 80px;
                              padding: 0 10px;
                              text-align: center;
                            "
                          >
                            <a-tag
                              v-if="item2.status === 1"
                              color="success"
                              style="margin: 0 auto; cursor: pointer"
                              @click="
                                item2.status = 0;
                                changeItemStatus();
                              "
                              >显示中
                            </a-tag>
                            <a-tag
                              v-else-if="item2.status === 0"
                              color="default"
                              style="margin: 0 auto; cursor: pointer"
                              @click="
                                item2.status = 1;
                                changeItemStatus();
                              "
                              >已隐藏
                            </a-tag>
                          </div>
                          <div class="action">
                            <a-button
                              type="link"
                              style="display: inline-block; padding: 0 5px"
                              v-if="item2.link === ''"
                              @click="setLink(index, index2)"
                              >设置链接
                            </a-button>
                            <a-button
                              type="link"
                              style="display: inline-block; padding: 0 5px"
                              v-else
                              @click="updateLink(index, index2)"
                              >更新链接
                            </a-button>
                            <a-button
                              type="link"
                              danger
                              style="display: inline-block; padding: 0 5px"
                              @click="deleteItem(2, index, index2)"
                              >删除
                            </a-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tree-button" v-if="treeData.length > 0">
        <a-button
          type="primary"
          style="width: 120px; margin-left: 100px"
          @click="submit()"
          >保存</a-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  message,
  Input,
  Button,
  Tag,
  Modal,
  Empty,
  Switch,
} from "ant-design-vue/es";
import { Sortable } from "sortablejs";
import IconFont from "../../utils/IconFont";
import File from "../../components/File";
import eventBus from "vue3-eventbus";
import set from "../../api/set";
import state from "../../store/state";

export default {
  name: "Nav",
  components: {
    IconFont,
    File,
    AInput: Input,
    AButton: Button,
    ATag: Tag,
    AModal: Modal,
    AEmpty: Empty,
    ASwitch: Switch,
  },
  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      sortSwitch: "false",
      sortStatus: false,
      coverVisible: false,
      linkVisible: false,
      chooseItemsUrl: [],
      chooseCoverIndex: null,
      chooseCoverIndex2: null,
      setLinkIndex: null,
      setLinkIndex2: null,
      setLinkData: "",
      sortable: null,
      allCloseNavStatus: "block",
      columns: ["标题", "封面", "状态", "操作"],
      treeData: [],
      treeChildrenData: [],
      level: 3,
    };
  },
  created() {},
  mounted() {
    this.getNav();
    eventBus.on("chooseItemsUrl", (val) => {
      this.chooseItemsUrl = val;
    });
  },
  methods: {
    // 初始化拖拽
    initSortable() {
      console.log("initSortable");
      const that = this;
      const elOne = document.querySelector(".tree-table-one");
      Sortable.create(elOne, {
        sort: true,
        handle: ".my-handle-one",

        //拖拽完成，移除拖拽之前的位置上的元素，在拖拽之后的位置上添加拖拽元素
        onEnd: (evt) => {
          console.log("一级拖拽完成");
          //旧索引
          const val = that.treeData[evt.oldIndex];
          that.treeData.splice(evt.oldIndex, 1);
          that.treeData.splice(evt.newIndex, 0, val);
        },
      });
      const elTwos = document.querySelectorAll(".tree-table-two");
      for (let m = 0; m < elTwos.length; m++) {
        Sortable.create(elTwos[m], {
          sort: true,
          disabled: that.sortStatus,
          handle: ".my-handle-two",
          // 元素被选中
          onChoose: function () {
            that.changeSort();
            that.setTreeChildren();
          },

          //拖拽完成，移除拖拽之前的位置上的元素，在拖拽之后的位置上添加拖拽元素
          onEnd: (evt) => {
            console.log("二级拖拽完成");
            console.log(evt);
            let ci = that.treeChildrenData[m].sort.split("-")[1];
            const val = that.treeData[ci].children[evt.oldIndex];
            that.treeData[ci].children.splice(evt.oldIndex, 1);
            that.treeData[ci].children.splice(evt.newIndex, 0, val);
          },
        });
      }
    },
    setTreeChildren() {
      this.treeChildrenData = [];
      for (let i = 0; i < this.treeData.length; i++) {
        if (this.treeData[i].children && this.treeData[i].children.length > 0) {
          this.treeChildrenData.push(this.treeData[i]);
        }
      }
    },
    changeSort() {
      console.log("重新排序");
      for (let i = 0; i < this.treeData.length; i++) {
        this.treeData[i].sort = "0-" + i;
        if (this.treeData[i].children && this.treeData[i].children.length > 0) {
          for (let j = 0; j < this.treeData[i].children.length; j++) {
            this.treeData[i].children[j].sort = "0-" + i + "-" + j;
          }
        }
      }
    },
    sort() {
      this.sortStatus = !this.sortSwitch;
      this.initSortable();
    },
    getNav() {
      let that = this;
      set
        .getNav()
        .then((res) => {
          console.log(res);
          if (res.code === 0) {
            that.treeData = res.data;
          } else {
            console.log(res);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //添加一级分类
    addNav(item) {
      this.treeData.push({
        id: "0",
        title: "",
        sort: "0-" + item.length,
        cover: "",
        closeNavStatus: "block",
        link: "",
        website_id: state.adminInfo.website.id,
        status: 1,
      });
    },
    //添加二级分类
    addNavTwo(index, item) {
      this.treeData[index].closeNavStatus = "block";

      const length =
        item.children && item.children.length > 0 ? item.children.length : 0;
      if (length === 0) {
        this.treeData[index].children = [];
      }
      this.treeData[index].children.push({
        id: "0",
        title: "",
        sort: "0-" + index + "-" + length,
        cover: "",
        link: "",
        website_id: state.adminInfo.website.id,
        status: 1,
      });
    },
    //修改状态提示
    changeItemStatus() {
      message.success("状态修改成功");
    },
    //折叠下级
    closeNav(index) {
      console.log("closeNav");
      if (index === undefined) {
        this.allCloseNavStatus =
          this.allCloseNavStatus === "block" ? "none" : "block";
        for (let i = 0; i < this.treeData.length; i++) {
          this.treeData[i].closeNavStatus = this.allCloseNavStatus;
        }
      } else {
        this.treeData[index].closeNavStatus =
          this.treeData[index].closeNavStatus === "block" ? "none" : "block";
      }
    },
    deleteItem(level, index, index2) {
      if (level === 1) {
        this.treeData.splice(index, 1);
      } else if (level === 2) {
        this.treeData[index].children.splice(index2, 1);
      }
    },
    inputChange(index) {
      if (this.treeData[index].title.length > 10) {
        this.treeData[index].title = this.treeData[index].title.substring(
          0,
          10
        );
      }
    },
    chooseCover(index, index2 = null) {
      this.chooseCoverIndex = index;
      if (index2 !== null) {
        this.chooseCoverIndex2 = index2;
      }
      this.coverVisible = true;
    },
    deleteCover(index, index2 = null) {
      this.treeData[index].cover = "";
      if (index2 !== null) {
        this.treeData[index].children[index2].cover = "";
      }
    },
    mouseMoveCover(index, index2 = null) {
      let coverTips = document
        .querySelectorAll(".tree-table-one-item")
        [index].querySelectorAll(".cover-tips")[0];
      if (index2 !== null) {
        coverTips = document
          .querySelectorAll(".tree-table-one-item")
          [index].querySelectorAll(".tree-table-two-item")
          [index2].querySelectorAll(".cover-tips")[0];
      }
      coverTips.style.display = "block";
    },
    mouseLeaveCover(index, index2 = null) {
      let coverTips = document
        .querySelectorAll(".tree-table-one-item")
        [index].querySelectorAll(".cover-tips")[0];
      if (index2 !== null) {
        coverTips = document
          .querySelectorAll(".tree-table-one-item")
          [index].querySelectorAll(".tree-table-two-item")
          [index2].querySelectorAll(".cover-tips")[0];
      }
      coverTips.style.display = "none";
    },
    mouseMoveCoverChange(index, index2 = null) {
      let coverTips = document
        .querySelectorAll(".tree-table-one-item")
        [index].querySelectorAll(".cover-tips")[0];
      if (index2 !== null) {
        coverTips = document
          .querySelectorAll(".tree-table-one-item")
          [index].querySelectorAll(".tree-table-two-item")
          [index2].querySelectorAll(".cover-tips")[0];
      }
      const coverTipsChangeBtn =
        coverTips.querySelectorAll(".cover-change-btn")[0];
      coverTips.style.display = "block";
      coverTipsChangeBtn.style.cursor = "pointer";
    },
    mouseMoveCoverDelete(index, index2 = null) {
      let coverTips = document
        .querySelectorAll(".tree-table-one-item")
        [index].querySelectorAll(".cover-tips")[0];
      if (index2 !== null) {
        coverTips = document
          .querySelectorAll(".tree-table-one-item")
          [index].querySelectorAll(".tree-table-two-item")
          [index2].querySelectorAll(".cover-tips")[0];
      }
      const coverTipsDeleteBtn =
        coverTips.querySelectorAll(".cover-delete-btn")[0];
      coverTips.style.display = "block";
      coverTipsDeleteBtn.style.cursor = "pointer";
    },
    chooseItem() {
      if (this.chooseItemsUrl.length !== 1) {
        message.info("只能选择1张图片");
        return false;
      } else {
        if (this.chooseCoverIndex2 === null) {
          this.treeData[this.chooseCoverIndex].cover =
            "https://oss.pigwan.net" + this.chooseItemsUrl[0];
        } else {
          this.treeData[this.chooseCoverIndex].children[
            this.chooseCoverIndex2
          ].cover = "https://oss.pigwan.net" + this.chooseItemsUrl[0];
        }
        this.chooseCoverIndex = null;
        this.chooseCoverIndex2 = null;
        this.coverVisible = false;
      }
    },

    setLink(index, index2 = null) {
      this.setLinkIndex = index;
      if (index2 !== null) {
        this.setLinkIndex2 = index2;
      }
      this.linkVisible = true;
    },
    updateLink(index, index2 = null) {
      this.setLinkIndex = index;
      this.setLinkData = this.treeData[index].link;
      if (index2 !== null) {
        this.setLinkIndex2 = index2;
        this.setLinkData = this.treeData[index].children[index2].link;
      }
      this.linkVisible = true;
    },
    chooseLink() {
      console.log(this.setLinkData);
      console.log(this.setLinkIndex);
      console.log(this.setLinkIndex2);

      if (this.setLinkIndex2 !== null) {
        this.treeData[this.setLinkIndex].children[this.setLinkIndex2].link =
          this.setLinkData;
      } else {
        this.treeData[this.setLinkIndex].link = this.setLinkData;
      }
      console.log(this.treeData);

      this.setLinkIndex = null;
      this.setLinkIndex2 = null;
      this.setLinkData = "";
      this.linkVisible = false;
    },
    //保存
    submit() {
      console.log(this.treeData);
      this.changeSort();
      console.log(this.treeData);
      set
        .updateNav(this.treeData)
        .then((res) => {
          if (res.code === 0) {
            message.success(res.msg);
            this.getNav();
          } else {
            message.error(
              "出现错误，请联系管理员！错误代码：" +
                res.code +
                ",错误信息：" +
                res.message
            );
          }
        })
        .catch((e) => {
          let error = JSON.parse(JSON.stringify(e));
          message.error(
            "出现错误，请联系管理员！错误代码：" +
              error.status +
              ",错误信息：" +
              error.message
          );
        });
    },
  },
};
</script>

<style scoped>
.tree-table-box {
  height: calc(100vh - 267px);
  position: relative;
  background-color: #ffffff;
}

.tree-table-one {
  height: calc(100vh - 395px);
  overflow: scroll;
  margin: 0 20px;
}

.tree-table {
  border-bottom: none !important;
}

.tree-table-out {
  margin-bottom: 10px;
  border: 1px solid #e9edef;
  border-radius: 2px;
}

.tree-table-in {
  border: 0;
}

.content {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e9edef;
}

.content-bg-one {
  background-color: #f4f6f8;
}

.content-bg-two {
  background-color: #fafdff;
}

.icon {
  padding: 0 20px;
  cursor: move;
}

.tree {
  padding: 0 10px;
  font-size: 14px;
  min-width: 450px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.icon-box {
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
}

.title {
  padding: 0 10px;
}

.image {
  max-width: 100px;
  min-width: 80px;
  padding: 0 10px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.image-content {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 auto;
}

.image-content img {
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid #e9edef;
}

.tree-button {
  width: 100%;
  height: 64px;
  line-height: 64px;
  position: absolute;
  bottom: 0;
  background-color: #ffffff;
}

.action {
  text-align: center;
  min-width: 160px;
  max-width: 160px;
  padding: 0 10px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.cover-delete-btn {
  display: inline-block;
  position: absolute;
  top: -11px;
  right: -6px;
}

.cover-change-btn {
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  position: relative;
  bottom: 20px;
}
</style>
